import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useMediaQuery } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { apiGet, apiPut } from '../api/api';
import { Urls, baseUrl } from '../api/urls';

export default function IpTable({ visitors, setSelectedVisitor, setVisitors }) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleUpdateBlockedStatus = async (id, blocked) => {
    try {
      await apiPut(`${baseUrl + Urls.putVisitor}/${id}`, { blocked });
      const response = await apiGet(`${baseUrl + Urls.getVisitors}`);
      setVisitors(response.visitors);
    } catch (error) {
      console.error('Error updating blocked status:', error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%', margin: 'auto', marginTop: '20px', overflowX: 'auto' }}>
      <Table sx={{ minWidth: isMobile ? 250 : 800 }} aria-label="responsive table">
        <TableHead>
          <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
            <TableCell sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }}>Adresă Ip</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }} align="right">Vizitări</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }} align="right">Data</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }} align="right">Blocat</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.8rem' : '1rem' }} align="right">Acţiune</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{
                backgroundColor: index % 2 === 0 ? '#e5e5e5' : '#cccccc',
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row" sx={{ padding: isMobile ? '5px' : '10px', fontSize: isMobile ? '0.75rem' : '1rem' }}>
                {row.ip}
              </TableCell>
              <TableCell align="right" sx={{ padding: isMobile ? '5px' : '10px', fontSize: isMobile ? '0.75rem' : '1rem' }}>
                {row.visits}
              </TableCell>
              <TableCell align="right" sx={{ padding: isMobile ? '5px' : '10px', fontSize: isMobile ? '0.75rem' : '1rem' }}>
                {new Intl.DateTimeFormat('ro-RO', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(row.date))}
              </TableCell>
              <TableCell align="right" sx={{ padding: isMobile ? '5px' : '10px' }}>
                <Button
                  variant="contained"
                  size={isMobile ? 'small' : 'medium'}
                  color={row.blocked ? 'error' : 'success'}
                  onClick={() => handleUpdateBlockedStatus(row.id, !row.blocked)}
                >
                  {row.blocked ? 'Blocat' : 'Deblocat'}
                </Button>
              </TableCell>
              <TableCell align="right" sx={{ padding: isMobile ? '2px' : '10px' }}>
                <Button
                  size="small"
                  color="inherit"
                  startIcon={<ArrowOutwardIcon />}
                  onClick={() => setSelectedVisitor(row.id)}
                  sx={{ minWidth: '0', padding: isMobile ? '2px' : '5px' }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
