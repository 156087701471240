import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Pagination,
} from '@mui/material';
import IpTable from './IpTable';
import { AppContext } from '../context/appContext';
import { apiGet } from '../api/api';
import { Urls, baseUrl } from '../api/urls';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import DetailedModal from './DetailedModal';
import Loader from './Loader';

const AdminModal = () => {
  const { isAdmin, setIsAdmin } = useContext(AppContext);
  const [visitors, setVisitors] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [visitor, setVisitor] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = () => setIsAdmin(false);

  // Fetch visitors when isAdmin, page, or rowsPerPage change
  useEffect(() => {
    if (isAdmin && localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined') {
      getVisitors();
    }
  }, [localStorage.getItem('token'), isAdmin, page, rowsPerPage]);

  const getVisitors = async () => {
    setLoading(true);
    const response = await apiGet(
      `${baseUrl + Urls.getVisitors}?page=${page}&limit=${rowsPerPage}`
    );
    setVisitors(response.visitors);
    setTotalItems(response.total);
    setLoading(false);
  };

  const setSelectedVisitor = (visitorId) => {
    if (visitorId !== null) {
      setVisitor(visitors.find((visitor) => visitor.id === visitorId));
      setShowDetails(true);
    }
  };

  // Update page state on pagination change. The useEffect will trigger getVisitors.
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Modal
        open={isAdmin}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle(isMobile)}>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: 'center',
              fontSize: isMobile ? '1.1rem' : '1.25rem',
              fontWeight: 'bold',
              fontFamily: 'Roboto Slab',
            }}
          >
            Bine ai venit pe pagina de administrare!
          </Typography>

          <Box sx={modalContentStyle}>
            {loading ? (
              <Loader isLoading={true} />
            ) : (
              // Pass the visitors directly since the backend returns paginated data.
              <IpTable
                visitors={visitors}
                setSelectedVisitor={setSelectedVisitor}
                setVisitors={setVisitors}
              />
            )}
          </Box>
          <Box sx={modalFooterStyle}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                variant="outlined"
                color="success"
                sx={{ width: '75%', height: '40px', fontSize: isMobile ? '0.8rem' : '0.875rem' }}
                startIcon={<ReplayCircleFilledIcon />}
                onClick={getVisitors}
              >
                Refresh
              </Button>
              <select
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(parseInt(e.target.value, 10));
                  // Reset to the first page when changing rows per page.
                  setPage(1);
                }}
                style={{ width: '20%', padding: '0.5rem', fontSize: '1rem' }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <Pagination
              count={Math.ceil(totalItems / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              sx={{ display: 'flex', justifyContent: 'center', paddingTop: '1rem' }}
            />
          </Box>
        </Box>
      </Modal>
      <DetailedModal visitor={visitor} showDetails={showDetails} setShowDetails={setShowDetails} />
    </div>
  );
};

const modalStyle = (isMobile) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '95%' : '80%',
  height: isMobile ? '90%' : '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: isMobile ? 2 : 4,
  borderRadius: 3,
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '90vh',
});

const modalContentStyle = {
  flex: 1,
  overflowY: 'auto',
  padding: '1rem',
};

const modalFooterStyle = {
  borderTop: '1px solid #ccc',
  padding: '1rem',
  backgroundColor: '#f9f9f9',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
};

export default AdminModal;
