
export const Urls = {
  isAdmin: "/admin",
  saveAdmin: "/saveAdmin",
  getVisitors: "/visitors",
  addAVisitor: "/visitor",
  putVisitor: "/visitor",
};
console.log(process.env.REACT_APP_PUBLIC_URL);

export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://botosanitaxi.ro/api';
